import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Alert, Box, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import * as actions from "../../store/actions";
import LoginForm from "../../components/security/LoginForm";
import Disclaimer from "../../components/security/Disclaimer";
import ResetPassword from "../../components/security/ResetPassword";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'url("/static/background-login.jpg")',
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  box: {
    height: "100%",
    padding: theme.spacing(3),
    textAlign: "justify",
  },
  boxGreen: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  boxLight: {
    backgroundColor: theme.palette.background.paper,
  },
  versionContainer: {
    color: theme.palette.text.secondary,
    position: "absolute",
    bottom: 16,
    right: 16,
  },
}));

const Login = ({ isAuthenticated, redirectPath, homePath, onAuth }) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);

  const onLoginSubmit = async (email, password) => {
    setErrorMessage(null);
    await onAuth(email, password);
  };

  const onLoginError = (error) => {
    setErrorMessage(error.message);
  };

  if (isAuthenticated) {
    if (!redirectPath || redirectPath === "/") {
      return <Redirect to={homePath} />;
    }

    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Typography color="textPrimary" gutterBottom variant="h1" className={classes.title}>
            Un avenir pour tous
          </Typography>
          <Grid container alignItems="stretch">
            <Grid item lg={12}></Grid>
            <Grid item lg={8}>
              <Box className={`${classes.box} ${classes.boxGreen}`}>
                <Disclaimer />
              </Box>
            </Grid>
            <Grid item xs>
              <Box className={`${classes.box} ${classes.boxLight}`}>
                <Typography color="textPrimary" variant="h2">
                  Accès UPPER
                </Typography>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <LoginForm onSubmit={onLoginSubmit} onError={onLoginError} />
                <Typography variant="body1">
                  <ResetPassword />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Typography variant="body1" className={classes.versionContainer}>
        {process.env.REACT_APP_VERSION}
      </Typography>
    </>
  );
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  redirectPath: PropTypes.string,
  homePath: PropTypes.string,
  onAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  redirectPath: state.auth.redirectPath,
  homePath: state.auth.homePath,
});

const mapDispatchToProps = (dispatch) => ({
  onAuth: (email, password) => dispatch(actions.auth(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
