import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import GuardedRoute from "../components/GuardedRoute";
import DashboardLayout from "../components/layout/DashboardLayout";
import MainLayout from "../components/layout/MainLayout";
import AgentCustomers from "../pages/Customers/AgentCustomers";
import CustomersDetail from "../pages/Customers/CustomersDetail";
import HubnupCustomers from "../pages/Customers/HubnupCustomers";
import Home from "../pages/Home";
import HubnupPortail from "../pages/Hubnup/HubnupPortail";
import PicturesLibrary from "../pages/Hubnup/PicturesLibrary";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import AgentInvoices from "../pages/Invoices/AgentInvoices";
import HubnupInvoices from "../pages/Invoices/HubnupInvoices";
import News from "../pages/News/News";
import NewsDetails from "../pages/News/NewsDetails";
import ResetPasswordScreen from "../pages/Authentication/ResetPasswordScreen";
import NewsEdit from "../pages/News/NewsEdit";
import AgentReminders from "../pages/Reminders/AgentReminders";
import AgentRemindersDetail from "../pages/Reminders/AgentRemindersDetail";
import AgentRemindersForm from "../pages/Reminders/AgentRemindersForm";
import AgentRemindersReporting from "../pages/Reminders/AgentRemindersReporting";
import HubnupReminders from "../pages/Reminders/HubnupReminders";
import HubnupRemindersDetail from "../pages/Reminders/HubnupRemindersDetail";
import HubnupRemindersForm from "../pages/Reminders/HubnupRemindersForm";
import HubnupSalesInvestment from "../pages/SalesInvestment/HubnupSalesInvestment";
import AgentSalesRealEstates from "../pages/SalesRealEstate/AgentSalesRealEstates";
import HubnupSalesRealEstates from "../pages/SalesRealEstate/HubnupSalesRealEstates";
import AgentSalesInvestment from "../pages/SalesInvestment/AgentSalesInvestment";
import SalesSummary from "../pages/SalesSummary/SalesSummary";
import Freshdesk from "../pages/SSO/Freshdesk";
import AgentDetails from "../pages/Team/AgentDetails";
import AgentTeam from "../pages/Team/AgentTeam";
import HubnupTeam from "../pages/Team/HubnupTeam";
import AgentUserEdit from "../pages/Users/AgentUserEdit";
import AgentUserAccount from "../pages/Users/AgentUserAccount";
import HubnupUsers from "../pages/Users/HubnupUsers";
import HubnupUsersDetails from "../pages/Users/HubnupUsersDetails";
import HubnupUsersForm from "../pages/Users/HubnupUsersForm";
import HubnupProspects from "../pages/Prospects/HubnupProspects";
import AgentProspects from "../pages/Prospects/AgentProspects";
import ProspectsDetail from "../pages/Prospects/ProspectsDetail";
import LibraryTrainersClub from "../pages/Library/LibraryTrainersClub";
import LibraryRac from "../pages/Library/LibraryRac";
import LibraryResponsablePole from "../pages/Library/LibraryResponsablePole";
import LibraryDocumentation from "../pages/Library/LibraryDocumentation";
import Training from "../pages/Training/Training";
import TrainingTeam from "../pages/Training/TrainingTeam";
import Forbidden from "../pages/Forbidden";
import HubnupVlp from "../pages/Vlp/HubnupVlp";
import AgentVlp from "../pages/Vlp/AgentVlp";
import LibraryModificationRequests from "../pages/Library/LibraryModificationRequests";
import HubnupNotifications from "../pages/Notification/HubnupNotifications";
import AgentReportingRegister from "../pages/Team/AgentReportingRegister";

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/(login|logout|not-found)" exact>
        <MainLayout>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/logout" exact component={Logout} />
            <Route
              path="/not-found"
              exact
              render={() => <h1>404 Not Found</h1>}
            />
          </Switch>
        </MainLayout>
      </Route>
      <Route path="/reset-password/:token" exact>
        <MainLayout>
          <ResetPasswordScreen />
        </MainLayout>
      </Route>
      <Route>
        <DashboardLayout>
          <Switch>
            {/* Agent Space */}
            <GuardedRoute
              path="/"
              exact
              component={Home}
              componentName="home_page"
            />
            <GuardedRoute
              path="/my/account"
              exact
              component={AgentUserAccount}
              componentName="account_page"
            />
            <GuardedRoute
              path="/my/account/edit"
              exact
              component={AgentUserEdit}
              componentName="account_modifications_page"
            />
            <GuardedRoute
              path="/my/reminders"
              exact
              component={AgentReminders}
              componentName="reminders_page"
            />
            <GuardedRoute
              path="/my/reminders/reporting"
              exact
              component={AgentRemindersReporting}
              componentName="reminders_reporting_page"
            />
            <GuardedRoute
              path="/my/reminders/create"
              exact
              component={AgentRemindersForm}
              componentName="reminders_edit_page"
            />
            <GuardedRoute
              path="/my/reminders/:id"
              exact
              component={AgentRemindersDetail}
              componentName="reminders_detail_page"
            />
            <GuardedRoute
              path="/my/reminders/:id/edit"
              exact
              component={AgentRemindersForm}
              componentName="reminders_edit_page"
            />
            <GuardedRoute
              path="/my/training"
              exact
              component={Training}
              componentName="training_page"
            />
            <GuardedRoute
              path="/my-sales/summary"
              exact
              component={SalesSummary}
              componentName="sales_summary_page"
            />
            <GuardedRoute
              path="/my-sales/real-estate"
              exact
              component={AgentSalesRealEstates}
              componentName="sales_real_estate_page"
            />
            <GuardedRoute
              path="/my-sales/real-estate/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/my-sales/investment"
              exact
              component={AgentSalesInvestment}
              componentName="sales_investment_page"
            />
            <GuardedRoute
              path="/my-sales/investment/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/my-sales/vlp"
              exact
              component={AgentVlp}
              componentName="sales_vlp_page"
            />
            <GuardedRoute
              path="/my-sales/vlp/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/my-team"
              exact
              component={AgentTeam}
              componentName="team_page"
            />
            <GuardedRoute
              path="/my-team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/my-team/:agentId/team"
              exact
              component={AgentTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/my-team/:agentId/real-estate"
              exact
              component={AgentSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/my-team/:agentId/investment"
              exact
              component={AgentSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/my-training-team"
              exact
              component={TrainingTeam}
              componentName="training_team_page"
            />
            <GuardedRoute
              path="/my-team-sponsor"
              exact
              component={AgentReportingRegister}
              componentName="team_track_for_sponsor_page"
            />
            <GuardedRoute
              path="/my-customers"
              exact
              component={AgentCustomers}
              componentName="customers_page"
            />
            <GuardedRoute
              path="/my-customers/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/my-prospects"
              exact
              component={AgentProspects}
              componentName="prospects_page"
            />
            <GuardedRoute
              path="/my-invoices"
              exact
              component={AgentInvoices}
              componentName="invoices_page"
            />
            <GuardedRoute
              path="/my-invoices/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/news"
              exact
              component={News}
              componentName="news_page"
            />
            <GuardedRoute
              path="/news/:id"
              exact
              component={NewsDetails}
              componentName="news_details_page"
            />
            <GuardedRoute
              path="/library/bibliotheque"
              exact
              component={LibraryDocumentation}
              componentName="documentation_page"
            />
            <GuardedRoute
              path="/library/bibliotheque/:id"
              exact
              component={LibraryDocumentation}
              componentName="documentation_page"
            />
            <GuardedRoute
              path="/library/rac"
              exact
              component={LibraryRac}
              componentName="rac_documentation_page"
            />
            <GuardedRoute
              path="/library/rac/:id"
              exact
              component={LibraryRac}
              componentName="rac_documentation_page"
            />
            <GuardedRoute
              path="/library/responsable-pole"
              exact
              component={LibraryResponsablePole}
              componentName="responsable_pole_documentation_page"
            />
            <GuardedRoute
              path="/library/responsable-pole/:id"
              exact
              component={LibraryResponsablePole}
              componentName="responsable_pole_documentation_page"
            />
            <GuardedRoute
              path="/library/trainers-club"
              exact
              component={LibraryTrainersClub}
              componentName="trainers_club_documentation_page"
            />
            <GuardedRoute
              path="/library/trainers-club/:id"
              exact
              component={LibraryTrainersClub}
              componentName="trainers_club_documentation_page"
            />
            <GuardedRoute
              path="/library/demandes-modification"
              exact
              component={LibraryModificationRequests}
              componentName="suivi_demande_modification_documentation_page"
            />
            <GuardedRoute
              path="/sso/freshdesk/login"
              exact
              component={Freshdesk}
            />

            {/* Hubn'UP Space */}
            <GuardedRoute
              path="/accueil"
              exact
              component={HubnupPortail}
              componentName="hubnup_home_page"
            />
            <GuardedRoute
              path="/administration/portail"
              exact
              component={HubnupPortail}
              componentName="portail_page"
            />
            <GuardedRoute
              path="/administration/users"
              exact
              component={HubnupUsers}
              componentName="users_page"
            />
            <GuardedRoute
              path="/administration/users/create"
              exact
              component={HubnupUsersForm}
              componentName="users_edit_page"
            />
            <GuardedRoute
              path="/administration/users/:id"
              exact
              component={HubnupUsersDetails}
              componentName="users_details_page"
            />
            <GuardedRoute
              path="/administration/users/:id/edit"
              exact
              component={HubnupUsersForm}
              componentName="users_edit_page"
            />
            <GuardedRoute
              path="/invoices"
              exact
              component={HubnupInvoices}
              componentName="hubnup_invoices_page"
            />
            <GuardedRoute
              path="/invoices/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/invoices/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/invoices/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/invoices/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/invoices/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/communication/pictures-library"
              exact
              component={PicturesLibrary}
              componentName="picture_library_page"
            />
            <GuardedRoute
              path="/communication/news"
              exact
              component={News}
              componentName="hubnup_news_page"
            />
            <GuardedRoute
              path="/communication/news/create"
              exact
              component={NewsEdit}
              componentName="news_edit_page"
            />
            <GuardedRoute
              path="/communication/news/:id"
              exact
              component={NewsDetails}
              componentName="news_details_page"
            />
            <GuardedRoute
              path="/communication/news/:id/edit"
              exact
              component={NewsEdit}
              componentName="news_edit_page"
            />
            <GuardedRoute
              path="/communication/reminders"
              exact
              component={HubnupReminders}
              componentName="hubnup_reminders_page"
            />
            <GuardedRoute
              path="/communication/reminders/create"
              exact
              component={HubnupRemindersForm}
              componentName="reminders_edit_page"
            />
            <GuardedRoute
              path="/communication/reminders/:id"
              exact
              component={HubnupRemindersDetail}
              componentName="reminders_detail_page"
            />
            <GuardedRoute
              path="/communication/reminders/:id/edit"
              exact
              component={HubnupRemindersForm}
              componentName="reminders_edit_page"
            />
            <GuardedRoute
              path="/communication/notifications"
              exact
              component={HubnupNotifications}
              componentName="notifications_page"
            />
            <GuardedRoute
              path="/sales/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="hubnup_sales_real_estate_page"
            />
            <GuardedRoute
              path="/sales/real-estate/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/sales/real-estate/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/sales/real-estate/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/sales/real-estate/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/sales/real-estate/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/sales/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="hubnup_sales_investment_page"
            />
            <GuardedRoute
              path="/sales/investment/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/sales/investment/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/sales/investment/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/sales/investment/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/sales/investment/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/sales/vlp"
              exact
              component={HubnupVlp}
              componentName="hubnup_sales_vlp_page"
            />
            <GuardedRoute
              path="/sales/vlp/clients/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/sales/vlp/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/sales/vlp/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/sales/vlp/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/sales/vlp/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/customers"
              exact
              component={HubnupCustomers}
              componentName="hubnup_customers_page"
            />
            <GuardedRoute
              path="/customers/:id"
              exact
              component={CustomersDetail}
              componentName="customers_detail_page"
            />
            <GuardedRoute
              path="/customers/:id/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/customers/:id/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/customers/:id/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/customers/:id/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/prospects"
              exact
              component={HubnupProspects}
              componentName="hubnup_prospects_page"
            />
            <GuardedRoute
              path="/prospects/:id"
              exact
              component={ProspectsDetail}
              componentName="prospects_detail_page"
            />
            <GuardedRoute
              path="/prospects/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/prospects/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/prospects/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/prospects/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            <GuardedRoute
              path="/team"
              exact
              component={HubnupTeam}
              componentName="hubnup_team_page"
            />
            <GuardedRoute
              path="/team/:agentId"
              exact
              component={AgentDetails}
              componentName="team_detail_page"
            />
            <GuardedRoute
              path="/team/:agentId/team"
              exact
              component={HubnupTeam}
              componentName="team_detail_team_page"
            />
            <GuardedRoute
              path="/team/:agentId/real-estate"
              exact
              component={HubnupSalesRealEstates}
              componentName="team_detail_real_estate_page"
            />
            <GuardedRoute
              path="/team/:agentId/investment"
              exact
              component={HubnupSalesInvestment}
              componentName="team_detail_investment_page"
            />
            {/* shared */}
            <Route path="/forbidden" exact component={Forbidden} />
            <Route render={() => <Redirect to="/not-found" />} />
          </Switch>
        </DashboardLayout>
      </Route>
    </Switch>
  );
};

export default AppRouter;
