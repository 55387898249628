import http from "../utils/http";

export const getFreshdeskUrl = async (redirectUri, state, nonce) => {
  const response = await http.get(`/sso/freshdesk/login?redirect_uri=${redirectUri}&state=${state}&nonce=${nonce}`);

  return response.data;
};

export const getOriadysUrl = async () => {
  const response = await http.get(`/sso/oriadys`);

  return response.data;
};

export const getCredentialO2s = async () => {
  const response = await http.get("api/o2s/getcredentials");

  return response.data;
};
